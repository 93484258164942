@import "common";

.page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  overflow-y: auto;

  @include mobile {
    padding: 72px 0 0;
  }

  &.wide {
    max-width: initial;
    > .page-inner > .page-inner-content {
      width: 100%;
    }
  }
  &.guest {
    margin-left: initial;
  }
  &.align-left > .page-inner {
    margin: initial;
  }

  &.centered {
    > .page-inner {
      margin: 0 auto;
      > .page-inner-content {
        width: 100%;
      }
    }
  }

  .page-inner {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    @include mobile {
      max-width: 100%;
    }
    @include desktop {
      padding-top: 75px;
    }
    .page-inner-content {
      height: 100%;
      @include mobile {
        width: 100%;
        padding: 0 0 60px;
      }
      @include desktop {
        display: flex;
        width: auto;
        max-width: 100%;
        flex-direction: column;
      }
    }
  }
  &.crm {
    .page-inner {
      width: 100%;
      max-width: 100%;
      min-height: 100%;
      .page-inner-content {
        background: #f5f6f8;
        max-width: 100%;
        @include desktop {
          margin-left: initial;
          max-width: initial;
          width: 100%;
        }
      }
    }
  }
}
