@import "common";

.faq-section {
    background: $PALETTE_THIRD;
    width: 100%;
    
    &.deafult {
        .section-inner {
            max-width: 1200px;
            margin: 0 auto;
            justify-content: flex-start;
            align-items: flex-start;
            @include mobile {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 25px 10px;
            }
            @include desktop {
                padding: 50px 20px;
                flex-direction: row;
                display: flex;
            }
            .left {
                flex: 1 0 50%;
            }
            .right {
                flex: 1 0 50%;
            }
            .share-question-btn {
                color: #808080;
                font-weight: 500;
                text-decoration: underline;
                font-size: 14px;
                padding-top: 8px;
            }
        }
    }


    &.dashboard {
        
        border-radius: 15px;
        max-width: 1000px;
        margin: 50px auto;
        background: transparent;
        .section-inner {
            flex-direction: column;
            width: 100%;
            
            .left {
                background: transparent;
            }
            .right {
                background: white;
                text-align: left;
                > div > div {
                    > div > div {
                        margin-top: 5px;
                    }
                }
                span {
                    // line-height: 36px;
                    
                }
            }

            svg {
                font-size: 25px;
                
            }
        }
    }
}