@import "common";

.footer {
    padding: 40px 15px 40px 15px;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;

     > .section-inner {
       display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        @include mobile {
          flex-direction: column;
        }

        .social-links {
          display: flex;
          

          @include mobile {
            padding-top: 15px;
            justify-content: center;
            align-items: center;
          }
          @include desktop {
            justify-content: flex-end;
            align-items: flex-end;
            flex: 0 0 30%;
          }
          a {
            width: 100%;
            max-width: 40px;
            height: 35px;
            padding: 10px;
            
            &.facebook-btn {
              max-width: 34px;
            }
            svg {
              width: 100%;
              height: 100%;
              color: #273751;
            }

          }
        }

        .left {
          &.logo-wrapper {
            display: flex;
            align-items: center;
            @include mobile {
              flex-direction: column;
            }
            .footer-logo {
              height: 35px;
              @include mobile {
                margin-bottom: 20px;
              }
              @include desktop {
                padding-right: 25px;
              }
            }
          }
        }

        h4 {
            font-size: 18px;
            font-weight: 600;
            max-width: 300px;
          }
  
      
          .section-left {
            display: flex;
            flex-direction: flex-start;
            align-items: flex-start;
            flex-direction: column;
      
            @include mobile {
              margin-bottom: 25px;
            }
            a {
              @include mobile {
                align-self: flex-start;
              }
            }
          }
          .section-right {
            display: flex;
            flex: initial;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            // flex: 1 0 38%;
            flex: 0 0 38%;
            @include mobile {
              flex-direction: column;
              width: 100%;
            }
            .footer-links-item {
              margin: 0 35px;
              @include mobile {
                margin: 0;
              }
            }
            ul {
              list-style-type: none;
              padding: 0;
              a {
                color: #1e73be;
                font-size: 13px;
                line-height: 1em;
              }
              li {
                margin: 10px 0;

                &.address {
                  line-height: 13px;
                }
              }
            }
          }

          .section-note {
            color: #96979b;
            font-size: 13px;
            letter-spacing: 0.5px;
            @include desktop {
              max-width: 86%;  
            }
      
            &.address {
              line-height: 14px;
            }
          }
          .section-note-commitment {
            
            color: #96979b;
            font-size: 13px;
            letter-spacing: 0.5px;
            @include mobile {
              padding: 25px 0 0;
            }
            @include desktop {
              padding: 70px 0 20px;
              max-width: 70%;
              // margin: 0 auto;
            }
          }
      }
      .links {
        padding: 0 0 30px;
        flex-direction: column !important;
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 30px;
        
        .items-wrapper {
          display: flex;
          flex-wrap: wrap;
        justify-content: space-between;
  
        
        .items {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 40px;
          padding-left: 0;
          width: 100%;
          flex-wrap: wrap;
          li {

            padding-bottom: 10px;
            @include desktop {
              flex-basis: 25%;
              width: 25%;
            }
            @include mobile {
              flex-basis: 50%;
              width: 50%;
            }
            a {
              color: #333;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        }
  

      }  
    .terms-privacy-links {
      justify-content: center;
      display: flex;
      @include mobile {
        flex-direction: row;
        padding-top: 20px;
        
      }
      .single-link {
        color: #96979b;
        font-size: 13px;
        letter-spacing: 0.5px;
        border-right: 2px solid #e7e7e7;
        padding: 0 5px;
        a {
          color: #848888;
        }
        &:last-child {
          border: none;
        }
        
      }
    }
    
  }