$PALETTE_PRIMARY: #7388d9; // Primary buttons
$PALETTE_SECONDARY: #fff;
$PALETTE_THIRD: #e4e7f7;

// Other colors
$PALETTE_GREEN: #00cb6f;
$PALETTE_RED: #FF0000;
$PALETTE_ORANGE: #ec8337;
$PALETTE_DARK_GREY: rgba(0, 0, 0, 0.65);
$PALETTE_GREY: #b7c0c8;
$PALETTE_LIGHT_GREY: #f2f2f2;

$PALETTE_GREY_BG: $PALETTE_LIGHT_GREY;

$PALETTE_BLACK: #000000;
$PALETTE_DISABLED: $PALETTE_LIGHT_GREY;
$PALETTE_DISABLED_SECONDARY: $PALETTE_GREY;
$PALETTE_LINK_BLUE: #4c76e5;
$PALETTE_PRIMARY_LIGHT: lighten($PALETTE_PRIMARY, 20%);
$PALETTE_PRIMARY_DARK: darken($PALETTE_PRIMARY, 5%);
