// @import "common";
@import 'common';

.homeowners-landing-page {
  overflow: auto;

  @include mobile {
    max-width: 100%;
  }
  h2 {
    color: #161616;
    font-size: 34px;
    @include mobile {
      text-align: center;
    }
  }
  > .page-inner {
    max-width: 100%  !important;
    > .page-inner-content {
      max-width: 100%;
      width: 100%;
    }
  }
  .section {
    h3 {
      font-size: 40px;
      color: #161616;
      padding: 25px 0;
      font-weight: 500;
      font-family: 'Merriweather', serif;
      @include mobile {
        text-align: center;
        font-size: 26px;
      }
    }
    .section-inner {
      max-width: 1280px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      @include mobile {
        max-width: 100%;
        padding: 0 10px;
        flex-direction: column;
      }
    }
    // HERO
    &.top {
      background-size: cover;
      background-position: 50%;
      min-height: 50vh;
      @include mobile {
        min-height: 60vh;
      }

      .section-inner {
        @include mobile {
          flex-direction: column-reverse;
        }
        > .left {
          max-width: 458px;
          @include mobile {
            flex: initial;
            max-width: 100%;
          }
          @include desktop {
            flex: 1 0 35%;
          }
        }
        > .right {
          flex: 1 0 40%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          @include mobile {
            display: none;
          }
          .multiple-offers-img {
            width: 20vw;
            height: auto;
            margin-right: 100px;
            margin-bottom: -10vh;
          }
        }
      }

      .section-title {
        font-size: 50px;
        line-height: 59px;
        padding-top: 55px;
        color: #ffffff;
        font-family: 'Merriweather', serif;

        @include mobile {
          text-align: center;
          font-size: 2.5rem;
          line-height: 30px;
          font-weight: 600;

          line-height: initial;
        }
        @include desktop {
          max-width: 100%;
        }
      }

      .section-note {
        color: white;
        font-size: 16px;
        text-align: left;
        padding: 10px 0 30px;
        @include mobile {
          text-align: center;
        }
      }

      .user-interest-tabs {
        color: white;
        width: 100%;
        // padding-top: 25px;
        text-align: center;
      }
    }

    &.why-sell {
      .section-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        .items {
          padding-top: 30px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          @include mobile {
            flex-direction: column;
          }
        }
        .why-sell-item {
          color: #161616;
          display: flex;
          @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 50px;
          }
          @include desktop {
            max-width: 40%;
            margin-bottom: 55px;
          }

          > .icon {
            // background: #dee2e0;
            width: 95px;
            // height: 80px;
            height: auto;
            min-width: 95px;
            // min-height: 80px;
            border-radius: 80px;
            img {
              // width: auto;
              height: auto;
              width: 100%;
              // height: 100%;
            }
          }
          > .right {
            @include desktop {
              padding-left: 35px;
            }
            > .title {
              font-size: 18px;
              font-weight: 500;
              padding-bottom: 9px;
              @include mobile {
                padding-top: 15px;
              }
            }
            > .desc {
              font-size: 15px;
              font-weight: 300;
            }
          }
        }
      }
    }

    &.recently-sold {
      .section-inner {
        display: flex;
        flex-direction: column;
        h3 {
          text-align: center;
        }
        > .items {
          display: flex;

          align-items: center;
          flex-wrap: wrap;
          @include mobile {
            justify-content: center;
          }
          @include desktop {
            padding: 0 20px;
            justify-content: space-between;
          }

          .listing-item {
            width: 270px;
            box-shadow: 2px 8px 19px 1px rgb(0 0 0 / 12%);
            border: 1px solid rgb(0 0 0 / 12%);
            padding: 0 0 13px;
            border-radius: 3px;
            margin: 0 10px 35px 10px;

            .property-img {
              width: 100%;
              height: 150px;
              background-size: cover;
              background-position: 50% 50%;
              border-radius: 2px;
              position: relative;
              .closed-tag {
                position: absolute;
                right: 7px;
                top: 7px;
                background: white;
                padding: 3px 5px;
                border-radius: 5px;
                color: $PALETTE_PRIMARY;
                box-shadow: 2px 8px 19px 1px rgb(0 0 0 / 22%);
                font-weight: 600;
                font-size: 13px;
                // border: 1px solid $PALETTE_PRIMARY;
              }
            }
            .listing-data {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-top: 15px;
              padding: 0 10px;
              &.top {
                padding: 10px 10px;
              }
              > .left {
                width: 47%;
                .closing-price {
                  font-weight: 700;
                  font-size: 21px;
                }
              }
              > .right {
                width: 49%;
                .prop-size {
                  font-size: 11px;
                }
              }

              .feature {
                color: #787b80;
                font-size: 11px;
                padding-bottom: 3px;
              }
            }
          }
        }
      }
    }
    &.slider-section {
      @include mobile {
        border-top: 1px solid #333;
      }
      @include desktop {
        margin-top: 70px;
      }

      .section-inner {
        margin: 0 auto;
        background: $PALETTE_THIRD;
        min-height: 300px;
        padding: 0 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mobile {
          padding: 0 15px 30px;
          text-align: center;
        }
        @include desktop {
          max-width: 1000px;
        }
        h3 {
          @include mobile {
            padding: 20px 15px 20px;
          }
          @include desktop {
            text-align: center;
            font-size: 37px;
          }
        }
        .slider-title {
          font-size: 20px;
          color: #4b1f4f;
          padding-bottom: 20px;
        }
        .slider-note {
          font-size: 14px;
          color: #4b1f4f;
          padding-top: 20px;
        }

        .profit-amount {
          background: $PALETTE_PRIMARY;
          color: white;
          padding: 0 3px;
        }
      }
    }
  }

  .places-wrapper {
    background: white;
    padding: 10px;
    border: 1px solid #dedede;
    box-shadow: 0 3px 15px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    @include mobile {
      padding: 8px;
    }
    .place-input-wrapper {
      display: flex;
      width: 100%;
      .places-auto-complete {
        width: 100%;

        input {
          height: 55px;
          max-height: 55px;
          line-height: 55px;
          border-color: transparent;

          &::placeholder {
            line-height: 55px;
          }
          &:focus {
            border-color: transparent;
            outline: none;
            box-shadow: none;
          }
        }
      }
      .place-submit-btn {
        // width: 60px;
        height: 50px;
        width: auto;
        padding: 5px 20px;
        border-radius: 7px;
        @include mobile {
          padding: 5px 10px;
        }
      }
    }
  }
}
