.modal-component {
	.ant-modal-content {
		.ant-modal-close {
			padding-top: 7px;
		}
		.ant-modal-header {
			padding: 24px 24px;
			.ant-modal-title {
				font-size: 22px;
				font-weight: 500;
				color: #333;
			}
		}
	}
}
